import React, { useState } from 'react';
import LoginForm from '../../forms/Login/LoginForm';
import NavBarLogin from '../../components/Login/NavBarLogin';
import { Typography } from '@material-ui/core'

function LoginPage() {

    return (
        <div>
            <NavBarLogin />
            <LoginForm />
            <Typography variant="body1" align='center' style={{ color: 'gray', padding: '0.5%'}}>
                Copyright © HMIS Facility Base System 2018-{new Date().getFullYear()}. </Typography>

        </div>
    )

}
export default LoginPage;


