import { Button, createTheme, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, ThemeProvider, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import CustomSnackBar from "../../components/controls/CustomSnackBar";
import Modals from "../../components/modal";
import XLSX from 'xlsx'
import moment from 'moment';

/////////////////API////////////////////////
import { exportCFRM } from '../../modals/exportcfrm';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      flexGrow: 1
    }
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#fcf0f2"
  },
  fontSize: {
    "& span:last-child": {
      fontSize: 13
    }
  },
  cardStyle: {

    marginTop: theme.spacing(0.9),
    marginBottom: theme.spacing(1),
  },
  cardStyleTwo: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.9),
    borderRadius: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
  },

  table: {
    width: '100%',
    background: '#fcf0f2',
    height: '10px',


  },
  cellOne: {
    borderBottom: 'none',
    color: '#808080',
    fontSize: '9pt',

  },
  cellTwo: {
    borderBottom: 'none',
    color: '#53344d',
    fontSize: '12pt',
    fontWeight: 'bold',


  },
  selected: {
    backgroundColor: "#DED4DA !important",
    color: '#482642'
  }
}));

const radioTheme = createTheme({
  palette: {
    primary: {
      main: "#482642"
    },
    secondary: {
      main: "#ffff"
    }
  }
});

export default function ExportCFRM() {

  const classes = useStyles();

  const [typee, setType] = useState(1)
  const [receiveDate, setReceiveDate] = useState('')
  const [addressDate, setAddressDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [select, setSelect] = useState([])
  const [error, setError] = useState('')
  const [openSnack, setOpenSnack] = useState(false)

  const typeHandle = async (event) => {
    setType(event.target.value);
  };

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const exportBtn = async () => {
    const type = typee===1? 'ReceivedDate' : select===2 ? 'AddressDate' : 'ResolvedDate'
    const parameter = {
      orgID: sessionStorage.getItem('org'),
      projID: sessionStorage.getItem('project'),
      sDate: receiveDate,
      eDate: addressDate,
      type : type
    }
    var allTableRes = []
    let valid = !receiveDate ? "Please Choose Start Date" :
    !addressDate ? "Please Choose End Date" :
              'valid';
    if(valid === 'valid')
    {
      setLoading(true)
      var wb = await XLSX.utils.book_new();
      allTableRes = await exportCFRM(parameter);
      
      if(allTableRes)
      {
        console.log("Table res =>",allTableRes)
        var cfrm = await XLSX.utils.json_to_sheet(allTableRes.data.data.getCFRMTable);
        XLSX.utils.book_append_sheet(wb, cfrm, "CFRM");
      }
      else {
        var empty = []
        XLSX.utils.book_append_sheet(wb, empty, "Empty");
      }
      XLSX.writeFile(wb, "InfoMx_CFRM_ExportDataset_" + `${moment(new Date()).format('DD-MM-YYYY')}` + ".xlsx");
      setLoading(false)
    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  const clear = () => {
    setReceiveDate('')
    setAddressDate('')
    setType(1)
  }

  return (
    <div style={{ width: '100%', height: '82.5vH', background: '#ffffff' }}>
      <Modals open={loading} />
      {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      <ThemeProvider theme={radioTheme}>
        <Typography variant="h5" align="center" style={{ color: '#53344d', background: '#ffffff', fontWeight: 'bold', padding: '1%' }}>
        Export By Date CFRM </Typography>
 

        <ThemeProvider theme={radioTheme}>
          <Grid
            container
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12} sm={6} md={3} style={{ margin: '2%' }}>
              <FormControl variant="filled" style={{ width: '90%' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Choose Type</Typography>
                  <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  style={{ width: '100%' }}
                  value={typee}
                  onChange={typeHandle}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null
                  }}>
                  <MenuItem value={1} classes={{ selected: classes.selected }}>Received Date</MenuItem>
                  <MenuItem value={2} classes={{ selected: classes.selected }}>Address Date</MenuItem>
                  <MenuItem value={3} classes={{ selected: classes.selected }}>Resolved Date</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
             </Grid>
        </ThemeProvider>

            


        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">

<Grid item xs={12} sm={6} md={3} >
            <CustomUnicefTextField
              id="filled-basic"
              type="date"
              style={{ width: '90%' }}
              label={<Grid row container><Typography color="#482642">Start Date</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              onChange={e => setReceiveDate(e.target.value)}
              value={receiveDate} />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">

<Grid item xs={12} sm={6} md={3} style={{  marginBlockStart: '3%' }}>
            <CustomUnicefTextField
              id="filled-basic"
              type="date"
              style={{ width: '90%' }}
              label={<Grid row container><Typography color="#482642">End Date </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              onChange={e => setAddressDate(e.target.value)}
              value={addressDate} />
          </Grid>
        </Grid>
       
       
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
      
        >
          <Grid container alignItems="center" justifyContent="center" style={{ padding: '20px', background: '#ffffff',marginTop:'30px' }} row>
            <Grid item xs={2} sm={2} md={2} style={{ width: '13%' }}>
              <Button
                variant="contained"
                style={{ background: '#482642', color: '#fff', width: '50%' }}
                onClick={exportBtn}  >Export</Button>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ width: '13%' }}>
              <Button
                variant="contained"
                style={{ background: '#482642', color: '#fff', width: '50%' }}
                onClick={clear} >Clear</Button>
            </Grid>
          </Grid>
        </Grid>



      </ThemeProvider>
    </div>
  )
}